<template>
    <div class="icons_screen content-grid">
        <Icon v-for="(item, index) in items" ref="items" :item="item" :cursorPosition="cursorPosition" :allActive="allActive" :key="index + item.count + item.active" @onClick="updActiveIcon(index)" @updCount="item.count+=1" />
    </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
export default {
    components: {
        Icon
    },
    data() {
        return {
            items: [
                {active: this.$router.options.base + 'media/main/icons_active/1.gif', play: this.$router.options.base + 'media/main/icons_play/1.gif', stop: this.$router.options.base + 'media/main/icons_stop/1.svg', audio: this.$router.options.base + 'media/main/audio/track_1.mp3', clicked: false, count: 0, topPosition: null,},
                {active: this.$router.options.base + 'media/main/icons_active/2.gif', play: this.$router.options.base + 'media/main/icons_play/2.gif', stop: this.$router.options.base + 'media/main/icons_stop/2.svg', audio: this.$router.options.base + 'media/main/audio/track_2.mp3', clicked: false, count: 0, topPosition: null,},
                {active: this.$router.options.base + 'media/main/icons_active/3.gif', play: this.$router.options.base + 'media/main/icons_play/3.gif', stop: this.$router.options.base + 'media/main/icons_stop/3.svg', audio: this.$router.options.base + 'media/main/audio/track_3.mp3', clicked: false, count: 0, topPosition: null,},
                {active: this.$router.options.base + 'media/main/icons_active/4.gif', play: this.$router.options.base + 'media/main/icons_play/4.gif', stop: this.$router.options.base + 'media/main/icons_stop/4.svg', audio: this.$router.options.base + 'media/main/audio/track_4.mp3', clicked: false, count: 0, topPosition: null,},
                {active: this.$router.options.base + 'media/main/icons_active/5.gif', play: this.$router.options.base + 'media/main/icons_play/5.gif', stop: this.$router.options.base + 'media/main/icons_stop/5.svg', audio: this.$router.options.base + 'media/main/audio/track_5.mp3', clicked: false, count: 0, topPosition: null,},
                {active: this.$router.options.base + 'media/main/icons_active/6.gif', play: this.$router.options.base + 'media/main/icons_play/6.gif', stop: this.$router.options.base + 'media/main/icons_stop/6.svg', audio: this.$router.options.base + 'media/main/audio/track_6.mp3', clicked: false, count: 0, topPosition: null,}
            ],
            cursorPosition: {
                x: null,
                y: null,
            }
        }
    },
    created() {
        window.addEventListener('mousemove', this.checkCursorPosition)
        window.addEventListener('scroll', this.checkScreenPosition)
    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.checkCursorPosition)
        window.removeEventListener('scroll', this.checkScreenPosition)
    },
    mounted() {
        this.updPositionIcons()
    },
    computed: {
        allActive() {
            return this.items.filter(el => !el.clicked).length == this.items.length
        }
    },
    methods: {
        checkCursorPosition(e) {
           this.cursorPosition = {
               x: e.clientX,
               y: e.clientY
           }
        },
        updPositionIcons() {
            if(window.innerWidth < 768) {
                this.items.forEach((el, index) => {
                    el.topPosition = parseInt(this.getTopOffset(this.$refs.items[index].$el))
                })
            }
        },
        checkScreenPosition() {
            const screenPosition = window.scrollY
            if(screenPosition > 500) {
                this.items.forEach((el, index) => {
                    if(el.topPosition - 100 < screenPosition  && el.topPosition + 150 > screenPosition) {
                        this.$refs.items.forEach((el, i) => {
                            if(i !== index) el.showPlay = false
                        })
                        const item = this.$refs.items[index]
                        if(!item.isPlay) {
                            item.showPlay = true
                            if(item.showActiveAnim) item.showActiveAnim = false
                        }
                    }
                })
                this.updPositionIcons()
            }
            
        },
        updActiveIcon(num) {
            const prevIndex = this.items.findIndex(el => el.clicked)
            if(prevIndex !== -1 && num !== prevIndex) this.items[prevIndex].count += 1
            const clickedItem = this.items[num].clicked
            this.items[num].clicked = clickedItem ? false : true
            this.items.forEach((item, index) => {
                if(index !== num) {
                    item.clicked = false
                    this.$refs.items[index].$refs.audio.pause()
                }
            })
        },
        getTopOffset(el) {
            return el.offsetTop - window.innerHeight / 2 + 85
        },
        // removePlayIcons(i) {
        //     this.$refs.items.forEach((el, index) => {
        //         if(index !== i) el.showPlay = false
        //     })
        // }
    }
}
</script>


<style lang="scss" scoped>
.icons_screen {
    background-color: #383434;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 36px 0;
    @media(max-width: 768px) {
        padding: 0;
    }
}
</style>