<template>
    <div>
       <MainScreen/>
       <SecondScreen/>
       <IconsScreen/>
       <WorksScreen />
    </div>
</template>

<script>
import MainScreen from '@/components/main/MainScreen.vue'
import SecondScreen from '@/components/main/SecondScreen.vue'
import IconsScreen from '@/components/main/IconsScreen.vue'
import WorksScreen from '@/components/main/WorksScreen.vue'

export default {
    components: {
        MainScreen,
        SecondScreen,
        IconsScreen,
        WorksScreen
    }
}
</script>
