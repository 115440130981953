<template>
    <button class="btn" :class="`btn_${type}`">{{ text }}</button>
</template>

<script>
export default {
    props: ['text', 'type'],
}
</script>

<style lang="scss" scoped>
.btn {
    cursor: pointer;
    border: none;
    &_main {
        width: 209px;
        height: 72px;
        box-shadow: 0 8px 15px 0 rgba(27, 27, 27, 0.47);
        background-image: linear-gradient(224deg, #1d1d1d 113%, #000 -30%, #ed644b -30%, #ddbf5d -30%);
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        @media(max-width: 768px) {
            width: 187px;
            height: 64px;
            font-size: 12px;
        }
    }
}
</style>